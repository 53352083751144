












































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import AddKindModal from './addKind-modal.vue'
import Editor from '@/components/Editor/index.vue'
import { getDicts } from '@/utils/dict'
import { Select, Option, Upload, Checkbox } from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Checkbox)

@Component({
  components: {
    AddKindModal,
    Editor
  }
})
export default class extends Vue {
  $constants: any
  // 字典数据
  // 展示类型
  showTypeDicts: any = []
  info = {
    title: '',
    type: '',
    coverImage: '',
    display: false,
    content: ``
  }
  showCoverImage = ''
  isHover = false
  isShowDialog = false
  loading = false

  async beforeCreate() {
    // 获取相关字典
    // 展示类型
    this.showTypeDicts = await getDicts('DISPLAY_TYPE')
  }

  created() {
    if (this.$route.query.id) {
      const id = Number(this.$route.query.id)
      this.getDetail(id)
    }
  }

  // 获取详情
  getDetail(id: number) {
    this.$api.property.getShowDetail(id).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.showCoverImage =
          this.$constants.common.imgPrefix + data.data.coverImage
        this.info = {
          title: data.data.title,
          type: data.data.type + '',
          coverImage: data.data.coverImage,
          display: data.data.display,
          content: data.data.content
        }
      }
    })
  }

  // 上传成功
  uploadSuccess(res: any) {
    this.info.coverImage = res.data
  }
  handleAvatarChange(file: any) {
    this.showCoverImage = URL.createObjectURL(file.raw)
  }

  // 上传前
  beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    const isPNG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isJPG && !isJPEG && !isPNG) {
      this.$message.error('上传照片只能是 JPG/JPEG/PNG 格式!')
    }
    if (!isLt2M) {
      this.$message.error('上传单张照片大小不能超过 2MB!')
    }
    return (isJPG || isJPEG || isPNG) && isLt2M
  }

  // 获取富文本内容
  getTxt(data: any) {
    this.info.content = data
  }

  // 返回
  goback() {
    this.$router.push('/property/show')
  }

  // 保存
  save() {
    if (
      !this.info.title ||
      !this.info.type ||
      !this.info.content ||
      !this.info.coverImage
    ) {
      this.$message.error('请输入必填项')
      return
    }
    this.loading = true
    if (this.$route.query.id) {
      // 编辑
      this.$api.property
        .editShowCont({
          id: Number(this.$route.query.id),
          ...this.info
        })
        .then((res: any) => {
          this.loading = false
          const data = res.data
          if (data.success) {
            this.$message.success(data.msg)
            this.goback()
          } else {
            this.$message.error(data.msg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    } else {
      this.$api.property
        .addShowCont({
          ...this.info
        })
        .then((res: any) => {
          this.loading = false
          const data = res.data
          if (data.success) {
            this.$message.success(data.msg)
            this.goback()
          } else {
            this.$message.error(data.msg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }

  // 关闭对话框
  closeDialog() {
    this.isShowDialog = false
  }
  // 操作对话框后统一回调
  afterOpt(kind: any) {
    this.loading = true
    this.$api.property
      .addShowType({
        groupCode: 'DISPLAY_TYPE',
        groupId: this.showTypeDicts[0].groupId,
        groupName: '微信小程序首页展示类型',
        name: kind,
        value: kind
      })
      .then((res: any) => {
        this.loading = false
        const data = res.data
        if (data.success) {
          this.$message.success(data.msg)
        } else {
          this.$message.error(data.msg)
        }
        this.closeDialog()
        getDicts('DISPLAY_TYPE').then((info: any) => {
          this.showTypeDicts = info
        })
      })
  }
}
